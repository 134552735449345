<template>
  <VApp>
    <JwGnbHeader v-if="isPc" />
    <PageHeader></PageHeader>
    <VMain>
      <slot />
    </VMain>
    <JwFooter v-if="showMainFooter" />
  </VApp>
</template>
<script setup>
import JwGnbHeader from "@/components/common/JwGnbHeader.vue";
import PageHeader from "@/components/common/PageHeader.vue";
import JwFooter from "@/components/common/JwFooter.vue";
import { useCommonStore } from "~/store/common";
import { useDisplay } from "vuetify";
// const showDrawer = ref(false);
const commonStore = useCommonStore();

const route = useRoute();
const showMainNav = ref(false);
/* const showMainNav = computed(() => {
  return !['section-id', 'shareDetail'].includes(route.name) && !isPc.value;
}); */
if (process.client) {
  document.body.className = "";
}

const { xlAndUp, lgAndUp } = useDisplay();
const isPc = computed(() => xlAndUp.value);

watch(
  () => route.fullPath, // route.fullPath 또는 route.path를 감시합니다.
  (newPath, oldPath) => {
    commonStore.appHeader.bottomNav = !["section-id", "shareDetail"].includes(route.name);
  },
  { immediate: true }
);

const showMainFooter = computed(() => {
  if (["section-id", "shareDetail"].includes(route.name)) {
    return false;
  } else {
    return isPc.value ? true : false;
  }
});

onBeforeMount(() => {
  if (!["section-id", "shareDetail"].includes(route.name) && !isPc.value) {
    showMainNav.value = true;
  } else {
    showMainNav.value = false;
  }
});
</script>
